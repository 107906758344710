



import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import {AxiosResponse, AxiosError} from 'axios';
import store from '../../../store';
import { AccountRequest } from '../../../../types/typescript-axios/api';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    StepNavi,
  },
})
export default class SignupFormConfirm extends Vue {
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get agree(): string {
    const $parent: any = this.$parent;
    return $parent.agree;
  }
  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private get name1(): string {
    const $parent: any = this.$parent;
    return $parent.name1;
  }
  private get name2(): string {
    const $parent: any = this.$parent;
    return $parent.name2;
  }
  private get birthYear(): string {
    const $parent: any = this.$parent;
    return $parent.birthYear;
  }
  private get companyName(): string {
    const $parent: any = this.$parent;
    return $parent.companyName;
  }
  private get departmentName(): string {
    const $parent: any = this.$parent;
    return $parent.departmentName;
  }
  private get sex(): number {
    const $parent: any = this.$parent;
    return $parent.sex;
  }
  private get consent(): boolean {
    const $parent: any = this.$parent;
    return $parent.consent;
  }
  private get password(): string {
    const $parent: any = this.$parent;
    return $parent.password;
  }
  private get mailReInput(): string {
    const $parent: any = this.$parent;
    return $parent.mailReInput;
  }
  private get passwordReInput(): string {
    const $parent: any = this.$parent;
    return $parent.passwordReInput;
  }
  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }
  private get tel1(): string {
    const $parent: any = this.$parent;
    return $parent.tel1;
  }
  private get tel2(): string {
    const $parent: any = this.$parent;
    return $parent.tel2;
  }
  private get tel3(): string {
    const $parent: any = this.$parent;
    return $parent.tel3;
  }

  public async signupAuth() {
    const params: AccountRequest = {
      sei: this.name1,
      mei: this.name2,
      birth_year: this.birthYear,
      sex: this.sex,
      comp_nm: this.companyName,
      div_nm: this.departmentName,
      email: this.mail,
      tel1: this.tel1,
      tel2: this.tel2,
      tel3: this.tel3,
      upassword: this.password,
      secret_quest_ans: this.secretQuestAans,
      exist_pic: this.consent,
      enq_1: '',
      enq_2: '',
      enq_3: '',
      enq_4: '',
      enq_5: '',
      enq_6: '',
    };
    await store.dispatch('account/signupAuth', params)
    .then((response: any) => {
      this.$router.replace({ path: '/signup/form/complete/' });
    }, (e: AxiosError) => {
      if (typeof e.response !== 'undefined') {
        const $p: any = this.$parent;
        // $p.status = e.response.status;
        $p.status = 500;
      }
      this.$router.replace('/signup/form/error/');
    });
  }
}
